import React, { Component, PureComponent } from 'react';
import {NavLink, Link} from 'react-router-dom'

import Catering from './Catering'
import './index.scss'

export const pages = [
  {path: "/order", label: "Order"},
  {path: "/catering", label: "Catering", component: Catering}
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label}) => (
          <li><NavLink className="page-link" exact to={path}>{label}</NavLink></li>
        ))}
      </ul>
    </nav>
  )}
}

export class Header extends Component {
  render() {
    return (
      <div className="order-benefits-wrapper">
        <div className="order-benefits">

          <div className="text">

            <p>Order below for Togo & Delivery</p>
            <div style={{textAlign: "right"}}>
              <Link
                style={{color: "white"}}
                to="/catering">Order Catering</Link>
            </div>


          </div>


        </div>
      </div>
    );
  }
}
export function subMenuFilter (subMenu) {

  const tag = subMenu.tag

  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const notSunday = [1,2,3,4,5,6].indexOf(day) !== -1

  if (tag && tag.indexOf('lunch') !== -1) {
    if (hours < 15) {
      return true
    } else {
      return false
    }
  }

 



  return true
}
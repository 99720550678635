import React from 'react'
// import './specials.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="catering-page">
      <div className="order-benefits-wrapper">
        <div className="order-benefits catering">

          <div className="text">

            <p>Order Catering Below</p>
            <div style={{textAlign: "right"}}>
              <a
                style={{color: "white"}}
                href="https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1541460079/las-casitas-catering_dy3svt.pdf">
                view PDF Menu</a>
            </div>


          </div>


        </div>
      </div>


      <AutoMenu tagsToShow={['catering']} />

      <div className="full-menu-link-wrapper">
        <Link to="/order" className="button">Go to Full Menu</Link>
      </div>
    </div>
  )
}

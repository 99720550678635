
import * as Components from "./Lams"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "lams"
}

